body {
  padding: 0;
  margin: 0;
  font-size: 15px;
}



.sp {
  display: none;
}

.main {
  .test1 {
      color: red;
  }
}

.first-view-img {
  border-bottom: solid;
  margin-bottom: 40px;
  width: 100%;
  height: 500px;
  object-fit: cover;
  box-sizing: border-box;
  position: relative;
}

.cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  height: 500px;
}

.cover h1 {
  color: #fff;
  text-align: center;
  line-height: 500px;
}

.box1 {
  margin: auto;
  padding: 20px;
  font-weight: bold;
  border: solid 4px #FF6600;
  width: 80%;
  min-height: 700px;
  box-sizing: border-box;
  text-align: center;
  margin-bottom: 30px;
}
.covid-19 {
  text-align: center;
  font-size: 30px;
  color: #FF6600;
}

.covid-19list ul {
  list-style: none;
  font-size: 60px;
  padding: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
  justify-content: space-between;
}
.covid-19list ul li:last-of-type {
  margin-left: auto;
}

.covid-19list li {
  width: 25%;
  padding-right: 8px;
  box-sizing: border-box;
}
.nayami {
  text-align: center;
  background-color: #faf6f4;
  padding: 64px 0;
}
.nayami-contents {
  margin-top: 64px;
}
.nayami-list ul {
  display: flex;
  justify-content: space-between;
  background-color: white;
  border: 5px solid #ea6505;
  border-radius: 10px;
  padding: 29px 29px ;
  box-sizing: border-box;
  margin: 0;
}

.equipment-used {
  margin: 64px 0;
}
.equipment-item {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.equipment-item-list {
  width: 45%;
  p {
    font-size: 24px;
    border-bottom: 1px solid silver;
    padding-bottom: 8px;
  }
  img {
    width: 100%;
  }
}
.konkento-about {
  font-size: 18px;
}

.covid-19img {
  width: 100%;
  height: 250px;
  display: table;
  justify-content: center;
  object-fit: cover;
}

.covid-19text ol li {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 30px;
  margin-left: 140px;
  text-align: left;
}

.covid-19text ol li::marker {
  color: #FF6600;
}

.box1 p {
  margin: 0; 
  padding: 0;
  text-align: center;
  font-weight: normal;
  font-size: 18px;
}
.container {
  width: 80%;
  margin: 0 auto;
  
}
.container h1 {
  font-size: 40px;
}

.oushin {
  h2 {
    font-size: 36px;
    text-align: center;
    padding-top: 20px;
  }
  p {
    text-align: center;
    line-height: 2;
  }
  .oushin-btn {
    display: block;
    text-decoration: none;
    color: #fff;
    background-color: #ea6505;
    width: 300px;
    margin: 32px auto;
    text-align: center;
    padding: 16px 0;
  }
}


.scene1 h1 {
  font-size: 30px;
  text-align: center;
  border-bottom: solid;
  padding-bottom: 200px;
  padding-top: 30px;
}


.scene1 h2 {
  font-size: 36px;
  text-align: center;
  padding-top: 20px;
}


.nayami-sub-title {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  border-top: 1px solid #151311;
  border-bottom: 1px solid #151311;
  padding: 12px 0;
  margin-bottom: 40px;
  width: 75%;
  margin: 0 auto;
}

.nayami-list0 {
  width: 75%;
  margin: 0 auto;
}

.nayami-list0 ul {
  padding: 0;
}

.nayami-list0 ul li {
  font-size: 15px;
  list-style: none;
  padding-bottom: 25px;
  text-align: center;
}

.nayami-img {
  width: 320px;
  height: 300px;
  border: 0;
}

.photo {
  text-align: right;
  margin-right: 200px;
  margin-top: 1px;
}

figure {
  margin: 0;
  padding: 0;
  line-height: 0;
  font-size: 100px;
  padding-top: 10px;
  border-bottom: solid;
  padding-bottom: 50px;
}

.customer-voice {
  width: 100%;


}
.customer-voice h2 {
  text-align: center;
  font-size: 36px;
}

.cv-list {
   width: 80%;
   margin: 0 auto;
   display: flex;
   flex-wrap: wrap;
   justify-content: space-around;

}

.cv-contents {
   width: 40%;
   border: solid 1px silver;
   border-top: none;
   border-radius: 8px;
   margin: 64px auto;
}

.cv-contents-title1 {
  background-color: #7f5945;
   color: #FFF;
   height: 440px;
   text-align: center;
   border-radius: 8px;
}

.cv-contents-title1 h3 {
  padding-top: 20px;
}

.cv-img2 {
  width: 100%;
  height: 300px;
  object-fit: cover;
  object-position: top;
}


.cv-contents2 {
  text-align: center;
}

.cv-contents-sentence {
  padding: 16px;
  line-height: 2;
}



.cv-contents-title2 {
  width: 90%;
  color: #2c2c2f;
  background: #7f5945;
  text-align: center;
  margin: auto;
  border-radius: 5px;
  margin-top: 35px;

}

.cv-contents-title2 p {
  font-size: 20px;
  color: white;
  padding: 0px;
  margin: 0px;
}

.cv-text2 p {
  font-size: 20px;
  padding-left: 30px;
}

.tensen {
  border-bottom: dashed 1px silver;
  width: 90%;
  margin: auto;
}

.cv-text3 p {
  margin: 0px;
  padding: 10px;
  font-size: 12px;
}
.pro{
  text-align: center;
  background-color: #faf6f4;
  height: 840px;
  margin: 0 auto;
  padding: 32px 0;
  position: relative;
}
.incho-title h1 {
  font-size: 36px;
  font-family: "メイリオ", Meiryo, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Osaka, "m s Ｐゴシック", sans-serif;
}

.incho-title h2 {
  border: 1px solid #151311;
  border-left: none;
  border-right: none;
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  
}
.incho-text {
  width: calc(100% - 312px);
  margin: 0 auto;
  text-align: left;
}

.incho-pro {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  margin-top: 64px;
}
.incho-pro p {
  width: 75%;
  line-height: 2;
  margin-bottom: 1em;
}
.incho-pro img {
  width: 312px;
  height: 312px;
  border-radius: 50%;
  object-fit: cover;
  object-position: top;
  padding: 90px 70px;
}

.flow-container {
  width: 80%;
  margin: 0 auto;
}

.flow-title {
  text-align: center;
  font-size: 36px;
}

.flow-time {
  border: solid 1px #ea650d;
  height: auto;
  color: #ea650d;
  border-radius: 5px;
  
}

.flow-time-title {
  font-weight: bold;
  text-align: center;
  text-decoration: underline;
  font-size: 32px;
  
}

.flow-time p {
  font-size: 25px;
  font-weight: bold;
  padding-left: 33%;
}

.flow-time span {
  color: black;
  font-weight: normal;
  font-size: 18px;
  margin-top: 0;
}


.flow-list-title  {
  color: #ea650d;
  border-bottom: solid 1px #ea650d;
  font-size: 18px;
}

.flow-list-title span {
  font-weight: bold;
  font-size: 40px;
  font-style: italic;
}
.riyu {
  text-align: center;
  background-color: #fff;
  margin: 0 auto;
  padding: 32px 0;
}
.riyu-list {
  padding-top: 20px;
  width: 80%;
  text-align: left;
  margin: 0 auto;
}


.title {
  border-radius: 20px;
  padding: 24px 24px;
  background-color: #7fb91e;
  color: white;
  display: flex;
  justify-content: space-between;
}

.heading {
  
}
.ryoukin-logo {
  text-align: center;
}
.table {
  border-collapse: collapse;
}

.ryoukin-list {
 margin: 64px auto;
 display: block;
 width: 80%;
 border-collapse: separate;
  box-sizing: border-box;
  text-indent: initial;
  border-spacing: 2px;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;
}
.ryoukin-list tr {
  width: 100%;
  font-weight: bold;
  border-bottom: 1px solid #e0e0e0;
  display: block;
}
.ryoukin-list tbody {
  display: block;
}
.ryoukin-list tr th {
  width: 15em;
  padding: 20px;
  text-align: center;
  font-weight: bold;
  background: #7cbd31;
  font-size: 20px;
  color: #fff;
}
.ryoukin-list tr td {
  width: calc(100% - 15em);
  padding: 20px;
  font-size: 18px;
  background: #fff;

}


.ryoukin-img {
  display: flex;
  padding: 0 124px;
  padding-bottom: 20px;
}
.contact {
  text-align: center;
  width: 80%;
  margin: 0 auto;
}

.contact-a h1 {
  position: relative;
  color: #333;
}

.contact-a h1::before {
  content: '';
  width: 100px;
  height: 1px;
  background-color: #ea6505;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

.contact-1 h2 {
  border-radius: 5px;
  padding: 10px 20px;
  background-color: #ea650d;
  color: white;
}
.contact-2 h2 {
  border-radius: 5px;
  padding: 10px 20px;
  background-color: #ea650d;
  color: white;
}
.contact-list {
 margin: 64px 272px;
 background-color: #faf6f4;
 padding: 32px;
}
.contact-list span {
  display: inline-block;
  padding: 0 5px;
  background-color: #ec3838;
  color: white;
  margin-left: 10px;

}
input {
  width: 70%;
  margin-bottom: 10px;
  padding: 8px;
}

textarea {
  width: 70%;
  margin-bottom: 10px;
  padding: 8px;
  height: 400px;
}

.form-button {
  text-align: center;
  margin: 64px 0;
  button {
      width: 250px;
      padding: 8px 0;
      background: none;
      outline: none;
      border: 1px solid #333;
  }
}

.access {
  width: 80%;
  margin: 0 auto;
}

.access-title p {
  text-align: center;
  font-weight: bold;
  border-top: solid 1px black;
  border-bottom: solid 1px black;
  padding-bottom: 15px;
  padding-top: 15px;
}

.access-title h2 {
  text-align: center;
  font-size: 36px;
}

.access-main {
  display: flex;
}

.map {
  width: 50%;
}

.access-text {
  width: 50%;
}

.route {
  width: 80%;
  margin: 0 auto;
}

.route-title p {
  text-align: center;
  font-weight: bold;
  border-top: solid 1px black;
  border-bottom: solid 1px black;
  padding-bottom: 15px;
  padding-top: 15px;
}

.route-title h2 {
  text-align: center;
  font-size: 36px;
}

.route-img {
  width: 100%;
  text-align: center;
  margin: 25px auto;
}

.route-list {
  list-style: none;
  width: auto;
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  gap: 40px 40px;
}

.route-list li {
  width: 30%;
  padding: 0;
  margin: 0;

}

.route-list li span {
  color: #7f5945;
  font-weight: bold;
  font-size: 25px;
}

.route-list-img {
  width: auto;
  max-width: 100%
}

.route-list li span {
  color: #7f5945;
  font-weight: bold;
  font-size: 25px;
}

.success-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  p {
    width: 200px;
    border: 1px solid #333;
    padding: 8px 24px;
    text-align: center;
  }
}



@media all and (max-width: 670px) {
  
  .pc {
      display: none;
  }
  .sp {
      display: block;
  }
  input, textarea {
    width: 100%;
  }
  .container {
    width: 90%;
  }
  .first-view-img {
      width: 100%;
      height: 300px;
  }
  .cover {
    height: 300px;
  }
  .cover h1 {
    line-height: 300px;
    font-size: 20px;
  }
  .oushin {
    width: 90%;
    margin: 0 auto;
    h2 {
      font-size: 24px;
      padding-top: 16px;
    }
    p {
      font-size: 13px;
    }
    .oushin-btn {
      width: 200px;
      margin: 32px auto;
      text-align: center;
      padding: 16px 0;
    }
  }
  .ryoukin-img {
      padding: 0;
  }
  .ryoukin-img img {
      width: 100%;
      height: 420px;
  }
  .box1 {
      width: 90%;
      display: block;
      text-align: center;
      min-height: 700px;
  }
  .covid-19 {
    font-size: 20px;
  }
  .covid-19text ol li {
      flex-wrap: wrap;
      margin-left: 0;
      margin: 0 ;
      padding: 0;
  }

  .covid-19img {
    height: 100px;
  }

  .scene1 h2 {
    font-size: 28px;
  }

  .scene1 p {
    font-size: 14px;
  }
  .topimg {
      max-width: 100%;
      height: auto;
  }
  .nayami-list ul li {
      display: block;
      margin-right: 10px;
      line-height: 2;
  }
  .nayami-list ul {
     padding: 29px 36px;
     display: block;
  }

  .nayami h1 {
    font-size: 28px;
  }
  .equipment-used {
    border-bottom: 1px solid silver;
    margin-bottom: 64px;
  }
  .equipment-item {
    display: block;
  }
  .equipment-item-list {
    width: 100%;
    p {
      font-size: 18px;
    }
  }
  .konkento-about {
    font-size: 13px;
  }
  
  .contact-list  {
      margin: 0;
  }
  .pro {
      height: auto;
  }
  .incho-pro {
      display: block;
      
  }
  .incho-pro img {
      width: 120px;
      height: 140px;
      border-radius: 50%;
      object-fit: cover;
      padding: 0;
  }
  .incho-pro p {
      width: 100%;
      height: auto;
  }
  .incho-text {
      display: block;
      width: 100%;
  }
  .cv-text2 p {
      padding-left: 0;
  }
  .cv-img2 {
    height: 150px;
  }
  .customer-voice h2 {
      font-size: 20px;
  }
  .ryoukin-img  {
      display: block;
      width: 100%;
      height: 100%;
  }
  .ryoukin-img img {
      width: 100%;
      height: 100%;
  }
  .ryoukin-img p {
      text-align: center;
  }
  .cv-text2 p {
      padding-left: 0;
  }
  .customer-voice h2 {
      font-size: 20px;
  }
  .riyu-list {
    width: 100%;
  }
  .covid-19text {
      text-align: left;
  }

  .cv-contents-title1 {
      height: 302px;
  
  }
  
  .cv-contents-title2 p {
      text-align: center;
  }
  
  .cv-list {
   width: 90%;
   margin: 0 auto;

  }

  .cv-contents {
      width: 100%;
  }

  .flow-time p {
      text-align: left;
      padding-left: 10%;
  }

  .access-main {
    display: block;
  }
  .map, .access-text {
    width: 100%;
  }

  .access-title p {
    font-weight: 400;
    font-size: 13px;
  }
}


